.closeIcon {
  position: absolute;
  top: 25px;
  right: 25px;
  cursor: pointer;
  color: white;
  border-radius: 50%;
  z-index: 20000;
}

.closeIcon:hover {
  opacity: 0.8;
}

.titleContainer {
  padding: 5px 0;
  font-size: 20px;
  color: white;
  font-family: 'Open-Sans-regular';
}

.imageContainer {
  max-width: 80vw;
  max-height: 80vh;
  min-width: none;
  min-height: none;
  opacity: 0.9;
  background-color: white;
  overflow: auto;
}

.frameContainer {
  width: 80vw;
  height: 80vh;
  opacity: 0.9;
  background-color: white;
}

.errorContainer {
  color: #ff4b4b;
  font-size: 18px;
  margin-top: 20px;
  display: flex;
  align-items: center;
  gap: 20px;
}
