.projectsContainer {
  height: calc(100vh - 205px);
  overflow-y: scroll;
  padding: 0 15px;
}

.mainContainer {
  padding: 15px;
  height: calc(100vh - 103px);
}

.headerContainer {
  display: flex;
  justify-content: space-between;
  margin: 20px 15px;
  gap: 10px;
}

.headerContainer h4 {
  font-family: 'Bebas-Neue-bold';
  font-size: 35px;
  color: #242342;
  margin-bottom: 0;
}

.inputDiv {
  display: flex;
  gap: 10px;
}

.teamMemberImgDiv {
  width: 50px;
  border-radius: 50%;
  height: 50px;
  border: 1px solid #707070;
  background: #d6d6d6;
  position: absolute;
  top: -22px;
}

.teamMemberImgDiv img {
  width: 100%;
  height: 100%;
  object-fit: cover;
  object-position: top center;
  border-radius: 50%;
}

.countMember {
  position: absolute;
  left: 125px;
  top: -7px;
  font-size: 12px;
}

.threeDots {
  cursor: pointer;
}

.paginationDiv {
  display: flex;
  justify-content: flex-end;
  padding: 20px 30px 20px 30px;
}

.linkText {
  text-decoration: underline;
  cursor: pointer;
  color: var(--dashboard-main-color);
  font-family: 'Open-Sans-medium' !important;
}

.status {
  padding: 7px 10px;
  border-radius: 14px;
  width: 100%;
  max-width: 140px;
  font-size: 14px;
  margin-bottom: 0px;
  cursor: pointer;
}

.status:hover {
  opacity: 0.9;
}

.color-red {
  background-color: #e74c3c; /* Red for prospect */
  color: white; /* White text on red background */
}

.color-brown { 
  background-color: #8bddfd;  /* Brown for broker-review */
  color: rgb(59, 59, 59);
}

.color-gray {
  background-color: #95a5a6; /* Gray for seller-review */
  color: black; /* Black text on gray background */
}

.color-pink {
  background-color: #ff7f50; /* Pink for pocket */
  color: white; /* White text on pink background */
}

.color-green {
  background-color: #27ae60; /* Green for active */
  color: white; /* White text on green background */
}

.color-orange {
  background-color: #f39c12; /* Orange for under-contract */
  color: white; /* White text on orange background */
}

.color-blue {
  background-color: #3498db; /* Blue for sold */
  color: white; /* White text on blue background */
}

.color-black {
  background-color: #8080802b;  /* Black for lost */
  color: gray;
}

.color-yellow {
  background-color: #f1c40f; /* Yellow for off-market */
  color: black; /* Black text on yellow background for visibility */
}

